import React, { useState, useEffect, useRef } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

function AppurtenanceMaps({ google }) {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const mapRef = useRef(null);
  const { id, name } = useParams();

  
  useEffect(() => {
    const fetchWaterSources = async () => {
      try {
        const token = localStorage.getItem('token');
        const userString = localStorage.getItem("user");
        const agency_code = userString ? JSON.parse(userString).agency_code : "User";

        if (!agency_code || !token) {
          console.log("Agency code or token not found in localStorage");
          return;
        }

        setProgress(50);

        const response = await axios.get(`https://api.ilms.co.tz/api/appurtenance_map`, {
          params: {
            agency_code: agency_code,
            token: token,
            fitting_type: id, 
          },
          headers: {
            "Content-Type": "application/json",
          },
        });

        //setData(response.data);
        updateMap(response.data);
        setProgress(100);
      } catch (error) {
        console.error('Error fetching data: ', error);
        setProgress(100);
      }
    };

    fetchWaterSources();
  }, []);



  const updateMap = (WaterSourceData) => {
    // Check if mapRef is null or not initialized
    if (!mapRef.current || !mapRef.current.map) {
      // If not initialized or null, return early or show a warning
      console.warn('Map reference is not initialized');
      return;
    }
  
    // Access the Google Map instance through mapRef.current.map
    const map = mapRef.current.map;
    const bounds = new google.maps.LatLngBounds();
  
    // Loop through the customer data and add markers
    WaterSourceData.forEach((waterSource) => {
      const location = waterSource.location.split(',');
      const latLng = new google.maps.LatLng(parseFloat(location[0]), parseFloat(location[1]));
      
      const contentString = `
      <div>
        <p><strong>Fitting Material:</strong> ${waterSource.fitting_material_name} </p>
        <p><strong>Fitting Size:</strong> ${waterSource.fitting_size_name} M3</p>
         <p><a href="https://www.google.com/maps/place/${waterSource.location}" target="_blank"><strong>Location:</strong> ${waterSource.location}</a></p>
        <p><strong>Fitting Status:</strong> ${waterSource.fitting_status_name}</p>
        <p><strong>Installation Year:</strong> ${waterSource.installation_year}</p>
        <p><strong>District Name:</strong> ${waterSource.district_name}</p>
        <p><strong>Zonal:</strong> ${waterSource.zonal_name}</p>
        <p><strong>Street:</strong> ${waterSource.street_name}</p>
        <p><strong>Ward:</strong> ${waterSource.ward_name}</p>
        <button onclick="location.href='/admins/apputenence-edit/${waterSource.id}'" style="background-color: #007bff; color: #fff; padding: 5px 10px; border: none; border-radius: 3px;">Edit</button>
      </div>
    `;

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      // Determine marker color based on connection_status_id and problem_id
      let markerIcon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
      
      
      const marker = new google.maps.Marker({
        position: latLng,
        map: map,
        title: `${waterSource.fitting_material_name}`,
        icon: markerIcon, // Set marker icon
      });

      bounds.extend(marker.getPosition());


      marker.addListener('click', () => {
        infowindow.open(map, marker);
      });
    });
    map.fitBounds(bounds);
};



  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
           <p className="h3 text-primary"> {name} Locations</p>

          </div>
            
              <div className="col-lg-11 mx-auto">
                <div style={{ width: 'auto', height: '600px' }}>
                  <Map
                    google={google}
                    zoom={6}
                    initialCenter={{ lat: -6.369028, lng: 34.888822 }}
                    ref={mapRef} // Assign the ref here
                  >
                    {/* Add any additional map components here */}
                  </Map>
                </div>
              </div>
            

          </div>
        </div>
      </div>
  
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCImwMGKH6YGOKP63KTiiVw-6oWhTP5lcw'
})(AppurtenanceMaps);
