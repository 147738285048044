import React, { useState, useEffect, useRef } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import Swal from 'sweetalert2';


function CustomerLocations({ google }) {
  const [progress, setProgress] = useState(0);
  const [zonalOptions, setZonalOptions] = useState([]);
  const [selectedZonal, setSelectedZonal] = useState('');
  const [wards, setWards] = useState([]);
  const [selectedWardId, setSelectedWardId] = useState('');
  const [streets, setStreets] = useState([]);
  const [selectedStreetId, setSelectedStreetId] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchZonalData = async () => {
      try {
        const token = localStorage.getItem('token');
        const userString = localStorage.getItem("user");
        const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  
        const response = await axios.get('https://api.ilms.co.tz/api/get_zones', {
          params: {
            agency_code: agency_code,
            token: token
          },
          headers: {
            "Content-Type": "application/json",
          }
        }); 
        setZonalOptions(response.data);
      } catch (error) {
        console.error('Error fetching zonal data: ', error);
      }
    };
  
    fetchZonalData();
  }, []);

  const getSelectedWards = async (event) => {
    const selectedZonalId = event.target.value;
    setSelectedZonal(selectedZonalId);

    try {
      const token = localStorage.getItem('token');
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      const response = await axios.get(`https://api.ilms.co.tz/api/get_wards`, {
        params: {
          agency_code: agency_code,
          token: token,
          selectedZonalId: selectedZonalId
        },
        headers: {
          "Content-Type": "application/json",
        }
      }); 
      setWards(response.data);
    } catch (error) {
      console.error('Error fetching wards data: ', error);
    }
  };

  const fetchStreets = async (event) => {
    const selectedWardId = event.target.value;
    setSelectedWardId(selectedWardId);

    try {
      const token = localStorage.getItem('token');
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  
      const response = await axios.get(`https://api.ilms.co.tz/api/get_streets`, {
        params: {
          agency_code: agency_code,
          token: token,
          selectedWardId: selectedWardId
        },
        headers: {
          "Content-Type": "application/json",
        }
      }); 
      setStreets(response.data);
    } catch (error) {
      console.error('Error fetching streets data: ', error);
    }
  };


  const handleSearch = async () => {
    try {
      setLoading(true);
      setProgress(50);
  
      const selectedZonal = document.getElementById('zonalSelect').value;
      const selectedWardId = document.getElementById('wardSelect').value;
      const selectedStreetId = document.getElementById('streetSelect').value;
  
      // Validate inputs
      if (!selectedZonal && !selectedWardId && !selectedStreetId) {
        // If none of the inputs are selected, display an error message
        setProgress(100);
        Swal.fire({
          title: 'Error!',
          text: 'Select at least one field',
          icon: 'error'
        });
        return false;
      }
  
      const token = localStorage.getItem('token');
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  
      let url = `https://api.ilms.co.tz/api/customer_location_search?agency_code=${agency_code}&token=${token}`;
  
      if (selectedZonal) {
        url += `&zonalSelect=${selectedZonal}`;
      }
      if (selectedWardId) {
        url += `&wardSelect=${selectedWardId}`;
      }
      if (selectedStreetId) {
        url += `&streetSelect=${selectedStreetId}`;
      }
  
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        }
      });
  
      setData(response.data);
      setProgress(100);
      setLoading(false);
  
      // Update map with customer locations and add markers with popups
      updateMap(response.data);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setProgress(100);
      setLoading(false);
    }
  };
  
  const updateMap = (customerData) => {
    // Check if mapRef is null or not initialized
    if (!mapRef.current || !mapRef.current.map) {
      // If not initialized or null, return early or show a warning
      console.warn('Map reference is not initialized');
      return;
    }
  
    // Access the Google Map instance through mapRef.current.map
    const map = mapRef.current.map;
    const bounds = new google.maps.LatLngBounds();
  
    // Loop through the customer data and add markers
    customerData.forEach((customer) => {
      const location = customer.location.split(',');
      const latLng = new google.maps.LatLng(parseFloat(location[0]), parseFloat(location[1]));
      
      const contentString = `
      <div>
        <p><strong>Name:</strong> ${customer.first_name} ${customer.middle_name} ${customer.sur_name}</p>
        <p><strong>Phone Number:</strong> ${customer.phone_number}</p>
         <p><a href="https://www.google.com/maps/place/${customer.location}" target="_blank"><strong>Location:</strong> ${customer.location}</a></p>
        <p><strong>Customer Type:</strong> ${customer.customer_type_name}</p>
        <p><strong>Service Provided:</strong> ${customer.service_provided_name}</p>
        <p><strong>Service Duration:</strong> ${customer.service_duration_name}</p>
      </div>
    `;

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      // Determine marker color based on connection_status_id and problem_id
      let markerIcon = '';
      if (customer.connection_status_id === 1) {
        markerIcon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'; // Green for connection status 1
      } else if (customer.connection_status_id === 2) {
        markerIcon = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'; // Blue for connection status 2
      } else if (customer.connection_status_id === 3) {
        markerIcon = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'; // Orange for connection status 3
      }

	else if (customer.connection_status_id === 4) {
        markerIcon = 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png'; //>
      }

      if (customer.problem_id === 2) {
        markerIcon = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'; // Red for problem_id 2
      }
      
      const marker = new google.maps.Marker({
        position: latLng,
        map: map,
        title: `${customer.first_name} ${customer.middle_name} ${customer.sur_name}`,
        icon: markerIcon, // Set marker icon
      });

      bounds.extend(marker.getPosition());


      marker.addListener('click', () => {
        infowindow.open(map, marker);
      });
    });
    map.fitBounds(bounds);
};

  
  
  

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="zonalSelect">Filter by Zonal</label>
                  <select
                    id="zonalSelect"
                    className="form-control"
                    value={selectedZonal}
                    onChange={getSelectedWards}
                  >
                    <option value="">Select Zonal</option>
                    {zonalOptions.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="wardSelect">Filter by Wards</label>
                  <select
                    id="wardSelect"
                    className="form-control"
                    value={selectedWardId}
                    onChange={fetchStreets}
                  >
                    <option value="">Select Ward</option>
                    {wards.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="streetSelect">Filter by Streets</label>
                  <select
                    id="streetSelect"
                    className="form-control"
                    value={selectedStreetId}
                    onChange={(event) => setSelectedStreetId(event.target.value)}
                  >
                    <option value="">Select Streets</option>
                    {streets.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-group">
                  <button className="btn mt-4 btn-primary btn-md" onClick={handleSearch}>
                    <i className="fa fa-search"></i> Search
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-2">
                <div className="col-lg-3 mt-4 float-right">
                  <i className="fa fa-map-marker-alt" style={{ fontSize: 14, color: 'green' }}>
                    &nbsp;CONNECTED
                  </i>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="col-lg-3 mt-4 float-right">
                  <i className="fa fa-map-marker-alt" style={{ fontSize: 14, color: 'blue' }}>
                    &nbsp;DISCONNECTED
                  </i>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="col-lg-3 mt-4 float-right">
                  <i className="fa fa-map-marker-alt" style={{ fontSize: 14, color: 'orange' }}>
                    &nbsp;PROSPECTIVE
                  </i>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="col-lg-3 mt-4 float-right">
                  <i className="fa fa-map-marker-alt" style={{ fontSize: 14, color: 'red' }}>
                    &nbsp;ILLEGAL
                  </i>
                </div>
              </div>
            </div>
          </div>
            
              <div className="col-lg-11 mx-auto">
                <div style={{ width: 'auto', height: '600px' }}>
                  <Map
                    google={google}
                    zoom={6}
                    initialCenter={{ lat: -6.369028, lng: 34.888822 }}
                    ref={mapRef} // Assign the ref here
                  >
                    {/* Add any additional map components here */}
                  </Map>
                </div>
              </div>
            

          </div>
        </div>
      </div>
  
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCImwMGKH6YGOKP63KTiiVw-6oWhTP5lcw'
})(CustomerLocations);
